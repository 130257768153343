import { Inject, Injectable, PLATFORM_ID, afterNextRender } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslationsService } from '@usheru-hff/shared/utils-translation';
import { Movie } from '@usheru-hff/shared/domain';
import { ImageService } from '@usheru-hff/shared/utils-image';
import { ENVIRONMENT, Environment } from '@environment';
import { ChannelService } from '@usheru-hff/shared/utils-channel';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private defaultFavIconUrl: string;
  private isBrowser: boolean;
  constructor(
    private channelService: ChannelService,
    private imageService: ImageService,
    private translations: TranslationsService,
    @Inject(ENVIRONMENT) private env: Environment,
    @Inject(PLATFORM_ID) private platformId
  ) {
    //this.defaultFavIconUrl = channel.faviconUrl; // todo: provide a real default if this one is missing
    this.defaultFavIconUrl = this.channelService.channel.logoSmallUrl || this.env.customPageParams.favicon || ''; //this is provisionary until we are able to get favicon from channel
    this.isBrowser = isPlatformBrowser(this.platformId);
    // here we ensure everypage has the default favicon at least.
    afterNextRender(() => {
      this.setFavicon();
    });
  }

  setTitle(title: string, defaultTitle?: string) {
    const translatekey = title;
    title = this.translations.translate(translatekey, defaultTitle);
    if (title === defaultTitle) {
      title = title.replace(/-/g, ' ');
      title.replace(/\b\w/g, l => l.toUpperCase());
    }

    if (this.isBrowser) {
      const titleEl = document.querySelector('title');
      titleEl.innerHTML = title;
    }

    return title;
  }

  setTitleExtended(args: { title: string; defaultTitle?: string; values?: { [key: string]: string } }): string {
    const { defaultTitle: defaultVal, values } = args;
    let { title } = args;
    const translatekey = title;
    title = this.translations.translate(translatekey, defaultVal || '');

    if (values) {
      for (const key in values) {
        title = title.replace(new RegExp(`_${key}_`, 'g'), values[key]);
      }
    }

    if (title === defaultVal) {
      title = title.replace(/-/g, ' ');
      title = title.replace(/\b\w/g, l => l.toUpperCase());
    }
    if (this.isBrowser) {
      const titleEl = document.querySelector('title');
      titleEl.innerHTML = title;
    }

    return title;
  }

  /**
   * Create of updates the favicon of the page. If no favicon is provided then the default one is used.
   * @param favicon
   */
  setFavicon(favicon: string = this.defaultFavIconUrl): void {
    if (this.isBrowser) {
      let link = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = favicon;
    }
  }

  /**
   * @deprecated
   */
  setMovieFavIcon(movie: Movie): void {
    // if no favicon found in movie then dont do anything keep the channel one
    if (!movie.faviconImageUrl) {
      return;
    }
    const favIconUrl = this.imageService.computeUrl(movie.faviconImageUrl);
    if (favIconUrl) {
      // (document.querySelector('link[rel="icon"]') as any).href = favIconUrl;
    }
  }

  /**
   * @deprecated
   */
  setDefaultFavicon(): void {
    if (this.isBrowser) {
      (document.querySelector('link[rel="icon"]') as HTMLAnchorElement).href = this.defaultFavIconUrl;
    }
  }

  setScrollTop(top = 0) {
    // document.documentElement.scrollTop = top;
  }

  private escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}
